<template>
  <v-form
    lazy-validation
    class="pt-2">

    <h6 class="subtitle mb-2">Er is een code gestuurd naar</h6>
    <h6 class="subtitle mb-2">{{ hidePhone(phone) }}</h6>

    <v-text-field
      v-model="smsCode"
      label="Code"
      type="number"
      dark
      required
    />
    <div class="mt-3">
      <v-btn
        id="passenger-login-btn"
        :loading="loader"
        large
        block
        dark
        color="pink"
        class="login-button"
        @click="submit">Inloggen</v-btn>
      <a
        id="passenger-create-account-link"
        href="#"
        class="mb-1 mt-3 white-12"
        @click="resendSms()">Code opnieuw sturen</a>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'VerifyPhoneViaSms',
  components: {},
  props: {
    loader: {
      type: Boolean,
      default: () => false
    },
    phone: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      smsCode: ''
    }
  },
  methods: {
    submit () {
      this.$emit('onSubmitVerifyPhone', this.smsCode)
    },
    resendSms () {
      this.$emit('onResendSms')
    },
    hidePhone (phone) {
      return phone.replace('+', '').replace(phone.substring(4, 9), '*****')
    }
  }
}
</script>

<style lang="scss" scoped>
    .subtitle {
        color: #ffffff;
    }

    /deep/ .v-label--active.primary--text {
        color: white !important;
    }
</style>
