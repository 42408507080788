<template>
  <div
    v-if="isPassenger"
    class="login-wrapper">
    <v-container grid-list-xl>
      <div class="custom-container text-center">
        <div>
          <div class="mb-5">
            <img
              src="../../assets/images/logo.svg"
              alt="Logo">
          </div>

          <v-form
            v-if="currentScreen === SCREENS.EMAIL"
            ref="formEmail"
            v-model="valid"
            lazy-validation
            @submit.prevent>
            <div class="custom-title black--text text-left mb-2">Inloggen bij DriveMe</div>
            <v-text-field
              v-if="!$store.getters.getAddToIosState"
              v-model="email"
              :rules="emailRules"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              type="email"
              placeholder="E-mail"
              required
              @keyup.enter="submitEmail"
            />
          </v-form>

          <v-form
            v-if="currentScreen === SCREENS.PASSWORD_LOGIN"
            ref="formPassword"
            v-model="valid"
            lazy-validation
            @submit.prevent>
            <div class="custom-title black--text text-left mb-2">Inloggen bij DriveMe</div>
            <v-text-field
              v-if="!$store.getters.getAddToIosState"
              v-model="email"
              :rules="emailRules"
              :max-length="TEXT_FIELD_MAX_LENGTH"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              type="email"
              placeholder="E-mail"
              required
              @keyup.enter="submitEmail"
            />
            <v-text-field
              v-if="!$store.getters.getAddToIosState"
              v-model="password"
              :rules="passwordRules"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              placeholder="Wachtwoord"
              type="password"
              required
              @keyup.enter="loginWithPassword"
            />
          </v-form>

          <v-form
            v-if="currentScreen === SCREENS.ADD_PHONE"
            lazy-validation
            @submit.prevent>
            <div class="phone-field">
              <v-text-field
                v-model="phone"
                color="black"
                background-color="ashyGray"
                class="custom-input py-0"
                type="tel"
                placeholder="06-12345678"
                hide-details
                required
                @keyup.enter="sendPhone(phone)"
              />
            </div>
          </v-form>

          <v-form
            v-if="currentScreen === SCREENS.VERIFY_PHONE"
            lazy-validation
            @submit.prevent>

            <div class="custom-title black--text mb-2">Er is een code gestuurd naar</div>
            <h6 class="black--text mb-3 phone-visible">
              {{ phone }}
              <div @click="backToAddPhone">
                <img
                  class="edit-phone"
                  width="20"
                  src="../../assets/icons/edit-icon.jpg"
                  alt="Edit">
              </div>
            </h6>

            <v-text-field
              v-model="smsCode"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              placeholder="0000"
              type="tel"
              required
              @keyup.enter="verifyPhone(smsCode)"
            />
          </v-form>

          <v-form
            v-if="currentScreen === SCREENS.SMS_LOGIN"
            ref="formSmsLogin"
            v-model="valid"
            lazy-validation
            @submit.prevent>

            <h6 class="black--text mb-3"> Login via SMS</h6>

            <div>
              <v-btn
                v-if="!$store.getters.getAddToIosState"
                :disabled="!valid"
                :loading="loginLoader"
                block
                color="black"
                class="btn-submit white--text"
                @click="onGetSmsClick">Stuur SMS naar {{ hiddenPhoneNumber }}</v-btn>
              <a
                href="#"
                class="custom-link lightBlue--text mt-3"
                @click="submitSmsScreen">Login met Wachtwoord</a>
            </div>
          </v-form>

          <v-form
            v-if="currentScreen === SCREENS.SMS_ENTER"
            ref="formSmsEnter"
            v-model="valid"
            lazy-validation
            @submit.prevent>

            <div class="custom-title black--text mb-2">Er is een code gestuurd naar</div>
            <h6 class="black--text mb-3">{{ hiddenPhoneNumber }}</h6>

            <v-text-field
              v-if="!$store.getters.getAddToIosState"
              v-model="smsCode"
              placeholder="0000"
              type="tel"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              required
              @keyup.enter="onSendCodeClick"
            />
          </v-form>

          <div v-if="currentScreen === SCREENS.EMAIL || currentScreen === SCREENS.PASSWORD_LOGIN || currentScreen === SCREENS.ADD_PHONE || currentScreen === SCREENS.VERIFY_PHONE || currentScreen === SCREENS.SMS_ENTER">
            <v-btn
              v-if="currentScreen === SCREENS.EMAIL"
              :loading="loginLoader"
              class="btn-submit white--text"
              color="black"
              block
              @click="submitEmail">
              Volgende
              <v-icon>arrow_forward</v-icon>
            </v-btn>
            <v-btn
              v-if="currentScreen === SCREENS.PASSWORD_LOGIN"
              :loading="loginLoader"
              class="btn-submit white--text"
              color="black"
              block
              @click="loginWithPassword">
              Volgende
              <v-icon>arrow_forward</v-icon>
            </v-btn>
            <v-btn
              v-if="currentScreen === SCREENS.ADD_PHONE"
              :loading="loginLoader"
              class="btn-submit white--text"
              color="black"
              block
              @click="sendPhone(phone)">
              Volgende
              <v-icon>arrow_forward</v-icon>
            </v-btn>
            <div v-if="currentScreen === SCREENS.VERIFY_PHONE">
              <v-btn
                block
                class="btn-submit white--text"
                color="black"
                @click="verifyPhone(smsCode)">
                Inloggen
                <v-icon>arrow_forward</v-icon>
              </v-btn>
              <div>
                <a
                  href="#"
                  class="custom-link lightBlue--text mt-3"
                  @click="onGetSmsClick()">Code opnieuw sturen</a>
              </div>
              <div>
                <a
                  href="#"
                  class="custom-link lightBlue--text mt-2"
                  @click="backToAddPhone()">Voer nieuwe telefoon in</a>
              </div>
            </div>
            <div v-if="currentScreen === SCREENS.SMS_ENTER">
              <v-btn
                v-if="!$store.getters.getAddToIosState"
                :disabled="!valid"
                :loading="loginLoader"
                block
                class="btn-submit white--text"
                color="black"
                @click="onSendCodeClick">
                Inloggen
                <v-icon>arrow_forward</v-icon>
              </v-btn>
              <a
                href="#"
                class="custom-link lightBlue--text mt-3"
                @click="onGetSmsClick">Code opnieuw sturen</a>
            </div>

            <div v-if="currentScreen === SCREENS.EMAIL || currentScreen === SCREENS.PASSWORD_LOGIN">
              <div>
                <router-link
                  v-if="currentScreen === SCREENS.PASSWORD_LOGIN"
                  class="custom-link lightBlue--text mt-3"
                  to="/session/forgot-password">Wachtwoord vergeten?</router-link>
              </div>
              <div>
                <a
                  href="/signup"
                  class="custom-link lightBlue--text mt-2">Nog geen account? Registreer hier</a>
              </div>

              <v-btn
                v-if="false"
                class="custom-link lightBlue--text"
                large
                block
                color="warning"
                @click="onCreateAccount">{{ $t('message.createAccount') }}</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
  <div
    v-else
    :style="{ background: loginScreenStyle.background }"
    :class="{ 'background-cover' : this.$store.getters.getEnv.envVersion.siteVersion !== 'ROLE_DRIVER' }"
    class="session-wrapper login-screen column-layout justify-center">
    <v-container
      grid-list-xl
      class="column-layout justify-center">
      <div class="text-xs-center">
        <div class="session-table-cell row-layout-center">
          <div class="session-content login-content">
            <div class="login-logo">
              <img
                :src="require('../../assets/images/logo.svg')"
                alt="Drive Me">
            </div>

            <v-form
              v-if="currentScreen === SCREENS.EMAIL"
              ref="formEmail"
              v-model="valid"
              lazy-validation
              class="pt-2"
              @submit.prevent>
              <v-text-field
                v-if="!$store.getters.getAddToIosState"
                v-model="email"
                :rules="emailRules"
                :max-length="TEXT_FIELD_MAX_LENGTH"
                class="red-validation mt-3"
                type="email"
                label="E-mail"
                dark
                required
                @keyup.enter="submitEmail"
              />
              <div class="mt-3">
                <v-btn
                  v-if="!$store.getters.getAddToIosState"
                  id="passenger-login-btn"
                  :disabled="!valid"
                  :loading="loginLoader"
                  large
                  block
                  dark
                  color="pink"
                  class="login-button"
                  @click="submitEmail">{{ $t('message.login') }}</v-btn>
                <a
                  id="passenger-create-account-link"
                  :href="$store.getters.getEnv.envVersion.siteVersion === 'ROLE_DRIVER' ? 'https://driveme.nl/voor-chauffeurs/aanmelden/' : '/signup'"
                  class="mb-1 mt-5 white-12">Nog geen account? Registreer hier</a>
                <v-btn
                  v-if="false"
                  id="passenger-create-account-btn"
                  large
                  block
                  color="warning"
                  @click="onCreateAccount">{{ $t('message.createAccount') }}</v-btn>
              </div>
            </v-form>

            <v-form
              v-if="currentScreen === SCREENS.SMS_LOGIN"
              ref="formSmsLogin"
              v-model="valid"
              lazy-validation
              class="pt-2"
              @submit.prevent>

              <h6 class="subtitle mb-2">Login via SMS</h6>

              <div class="mt-3">
                <v-btn
                  v-if="!$store.getters.getAddToIosState"
                  id="passenger-login-btn"
                  :disabled="!valid"
                  :loading="loginLoader"
                  large
                  block
                  dark
                  color="pink"
                  class="login-button"
                  @click="onGetSmsClick">Stuur SMS naar {{ hiddenPhoneNumber }}</v-btn>
                <a
                  id="passenger-create-account-link"
                  href="#"
                  class="mb-1 mt-3 white-12"
                  @click="submitSmsScreen">Login met Wachtwoord</a>
              </div>
            </v-form>

            <v-form
              v-if="currentScreen === SCREENS.SMS_ENTER"
              ref="formSmsEnter"
              v-model="valid"
              lazy-validation
              class="pt-2"
              @submit.prevent>

              <h6 class="subtitle mb-2">Er is een code gestuurd naar</h6>
              <h6 class="subtitle mb-2">{{ hiddenPhoneNumber }}</h6>

              <v-text-field
                v-if="!$store.getters.getAddToIosState"
                v-model="smsCode"
                label="Code"
                type="number"
                dark
                required
                @keyup.enter="onSendCodeClick"
              />
              <div class="mt-3">
                <v-btn
                  v-if="!$store.getters.getAddToIosState"
                  id="passenger-login-btn"
                  :disabled="!valid"
                  :loading="loginLoader"
                  large
                  block
                  dark
                  color="pink"
                  class="login-button"
                  @click="onSendCodeClick">Inloggen</v-btn>
                <a
                  id="passenger-create-account-link"
                  href="#"
                  class="mb-1 mt-3 white-12"
                  @click="onGetSmsClick">Code opnieuw sturen</a>
              </div>
            </v-form>

            <v-form
              v-if="currentScreen === SCREENS.PASSWORD_LOGIN"
              ref="formPassword"
              v-model="valid"
              lazy-validation
              class="pt-2"
              @submit.prevent>
              <v-text-field
                v-if="!$store.getters.getAddToIosState"
                v-model="email"
                :rules="emailRules"
                :max-length="TEXT_FIELD_MAX_LENGTH"
                class="red-validation mt-3"
                type="email"
                label="E-mail"
                dark
                required
                @keyup.enter="loginWithPassword"
              />
              <v-text-field
                v-if="!$store.getters.getAddToIosState"
                v-model="password"
                :rules="passwordRules"
                class="red-validation"
                label="Wachtwoord"
                type="password"
                dark
                required
                @keyup.enter="loginWithPassword"
              />
              <div class="mt-3">
                <v-btn
                  v-if="!$store.getters.getAddToIosState"
                  id="passenger-login-btn"
                  :disabled="!valid"
                  :loading="loginLoader"
                  large
                  block
                  dark
                  color="pink"
                  class="login-button"
                  @click="loginWithPassword">{{ $t('message.login') }}</v-btn>
                <router-link
                  id="passenger-forgot-password-link"
                  class="mb-1 white-12 forgot-password"
                  to="/session/forgot-password">Wachtwoord vergeten?</router-link>
                <a
                  id="passenger-create-account-link"
                  :href="$store.getters.getEnv.envVersion.siteVersion === 'ROLE_DRIVER' ? 'https://driveme.nl/voor-chauffeurs/aanmelden/' : '/signup'"
                  class="mb-1 mt-5 white-12">Nog geen account? Registreer hier</a>
                <v-btn
                  v-if="false"
                  id="passenger-create-account-btn"
                  large
                  block
                  color="warning"
                  @click="onCreateAccount">{{ $t('message.createAccount') }}</v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import SessionSliderWidget from 'Components/Widgets/SessionSlider'
import Phone from '../passenger/components/form/Phone'
import AppConfig from 'Constants/AppConfig'
import Request from '../../helpers/request'
import { oneSignalInit } from '../../helpers/oneSignal.js'
import { TEXT_FIELD_MAX_LENGTH } from '../../constants/common'
import VerifyPhoneViaSms from '../passenger/components/form/VerifyPhoneViaSms'
import { getEnvVersion } from '../../helpers/helpers'

const SCREENS = {
  EMAIL: 'EMAIL',
  SMS_LOGIN: 'SMS_LOGIN',
  SMS_ENTER: 'SMS_ENTER',
  PASSWORD_LOGIN: 'PASSWORD_LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  ADD_PHONE: 'ADD_PHONE',
  VERIFY_PHONE: 'VERIFY_PHONE'
}

export default {
  components: {
    VerifyPhoneViaSms,
    Phone,
    SessionSliderWidget
  },
  mixins: [Request],
  data () {
    return {
      SCREENS,
      TEXT_FIELD_MAX_LENGTH,
      loginLoader: false,
      checkbox: false,
      valid: false,
      email: '',
      emailRules: [],
      password: '',
      passwordRules: [],
      appLogo: AppConfig.appLogo2,
      brand: AppConfig.brandName,
      rideList: null,
      currentScreen: SCREENS.EMAIL,
      hiddenPhoneNumber: '',
      smsCode: null,
      phone: '',
      isPassenger: false
    }
  },
  computed: {
    loginScreenStyle () {
      return {
        background: `linear-gradient(to bottom, ${this.$store.getters.colorGuide.login.gradient.from} 40vh, ${this.$store.getters.colorGuide.login.gradient.to} 100%), url(${this.$store.getters.colorGuide.login.image}) ${this.$vuetify.breakpoint.xsOnly ? this.$store.getters.colorGuide.login.imageParams : this.$store.getters.colorGuide.login.imageParamsDesktop}`
      }
    }
  },
  watch: {
    'email' (val) {
      this.emailRules = []
    },
    'password' (val) {
      this.passwordRules = []
    }
  },
  beforeMount () {
    this.$route.query.email != null &&
      (this.email = this.$route.query.email)
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'
  },
  mounted () {
    const timeout = setTimeout(() => {
      if (this.isPassenger && this.$store.state.auth.user !== '' && this.$store.state.auth.user) {
        if (!this.$store.state.auth.user.phone && this.$store.state.auth.user.email) {
          this.currentScreen = SCREENS.ADD_PHONE
        }
      }
    }, 1000)

    if (document.getElementById('talk-chat-script')) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }

    return () => clearTimeout(timeout)
  },
  methods: {
    getRides (successCB) {
      this.request('GET', '/passengers/rides?pageSize=200', {}, ({ data }) => {
        if (successCB) {
          successCB(data)
        }
      }, (loader) => { this.loading = loader })
    },
    getMatchRedirectUrl () {
      if (!this.rideList) {
        return null
      }

      const subscribedRides = []
      const draftRides = []
      const paidPaidOutRide = []
      const startedElectedRides = []
      const tomorrow = moment(new Date()).add(1, 'days')

      this.rideList.forEach(ride => {
        if (ride.status && ride.status.alias === 'subscribed') {
          subscribedRides.push(ride)
        }

        if (ride.status && ride.status.alias === 'draft') {
          draftRides.push(ride)
        }

        if (ride.status && (ride.status.alias === 'paid' || ride.status.alias === 'paid_out')) {
          paidPaidOutRide.push(ride)
        }

        if (ride.status && (ride.status.alias === 'started' || (ride.status.alias === 'elected' && moment(ride.startAt) < tomorrow))) {
          startedElectedRides.push(ride)
        }
      })

      if (subscribedRides.length) {
        return `/ride/${subscribedRides[0].id}`
      }

      if (draftRides.length) {
        return `/create-ride/stops/${draftRides[0].id}`
      }

      if (startedElectedRides.length) {
        return `/ride/${startedElectedRides[0].id}`
      }

      if (!this.rideList.length || paidPaidOutRide.length === this.rideList.length) {
        return '/rides'
      }

      return null
    },
    async handleRedirect ({ userData, currentRoute, isPassenger }) {
      const matchRedirectUrl = this.getMatchRedirectUrl()

      if (isPassenger) {
        const { data: appConfigData } = await this.request('GET', '/me/app-config')

        oneSignalInit(appConfigData.oneSignal, AppConfig)
      }

      if (isPassenger && matchRedirectUrl) {
        this.$router.push(matchRedirectUrl)
      } else {
        this.$router.push(currentRoute[0].redirect)
      }
    },
    checkEmail () {
      return new Promise(resolve => {
        const body = {
          data: {
            email: this.email
          }
        }

        this.requestNoAuth('POST', '/emailing/check', body, ({ data }) => {
          resolve(data)
        })
      })
    },
    getSms () {
      return new Promise(resolve => {
        const body = {
          data: {
            email: this.email
          }
        }

        this.requestNoAuth('POST', '/phone/email/token', body, ({ data }) => {
          resolve(data)
        })
      })
    },
    sendCode () {
      return new Promise(resolve => {
        const body = {
          data: {
            code: this.smsCode,
            email: this.email
          }
        }

        this.requestNoAuth('POST', '/phone/email/code', body, ({ data }) => {
          resolve(data)
        })
      })
    },
    submitSmsScreen (e) {
      if (e) e.preventDefault()
      this.currentScreen = SCREENS.PASSWORD_LOGIN
    },
    async onSendCodeClick () {
      try {
        const data = await this.sendCode()

        // storeToken(data)

        let dataUserWithPhone = {
          ...data.user,
          phone: data.user.phone
        }

        this.constructRequests()

        this.$store.dispatch('loginUser', { data: dataUserWithPhone })
        this.$store.dispatch('setUser', dataUserWithPhone)

        this.request('GET', '/me', {}, ({ data }) => {
          let currentRoute = this.authorizeUser(data)

          if (currentRoute) {
            if (this.$route.query.redirect != null) {
              this.$router.push({ path: this.$route.query.redirect })
            } else {
              const isPassenger = (
                data.roles.includes('ROLE_PASSENGER') &&
                  !data.roles.includes('ROLE_ADMIN') &&
                  !data.roles.includes('ROLE_SIGN_UP') &&
                  !data.roles.includes('ROLE_DRIVER')
              )

              if (isPassenger) {
                return this.getRides((ridesData) => {
                  this.rideList = ridesData.data
                  this.handleRedirect({ userData: data, currentRoute, isPassenger })
                })
              }

              this.handleRedirect({ userData: data, currentRoute, isPassenger })
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async onGetSmsClick () {
      try {
        await this.getSms()
        this.currentScreen = SCREENS.SMS_ENTER
      } catch (error) {
        console.log(error)
      }
    },
    submitEmail (e) {
      e.preventDefault()

      this.emailRules = [
        v => !!v || 'Is verplicht',
        v => v.length > 6 || 'Vul je e-mailadres in'
        // v =>
        //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        //   'E-mail must be valid'
      ]

      setTimeout(async () => {
        if (this.$refs.formEmail.validate()) {
          try {
            const data = await this.checkEmail()

            this.currentScreen = SCREENS.PASSWORD_LOGIN
            this.hiddenPhoneNumber = data.phone

            if (data.phone) {
              this.currentScreen = SCREENS.SMS_LOGIN
            }
          } catch (error) {
            console.log(error)
          }
        }
      }, 1)
    },
    loginWithPassword () {
      this.emailRules = [
        v => !!v || 'Is verplicht',
        v => v.length > 6 || 'Vul je e-mailadres in'
        // v =>
        //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        //   'E-mail must be valid'
      ]
      this.passwordRules = [v => !!v || 'Vul je wachtwoord in']

      setTimeout(() => {
        if (this.$refs.formPassword.validate()) {
          this.authorization(this.email, this.password, ({ data }) => {
            this.request('GET', '/me', {}, ({ data }) => {
              let currentRoute = this.authorizeUser(data)
              if (currentRoute) {
                if (this.$route.query.redirect != null) {
                  this.$router.push({ path: this.$route.query.redirect })
                } else {
                  const isPassenger = (
                    data.roles.includes('ROLE_PASSENGER') &&
                    !data.roles.includes('ROLE_ADMIN') &&
                    !data.roles.includes('ROLE_SIGN_UP') &&
                    !data.roles.includes('ROLE_DRIVER')
                  )

                  if (this.isPassenger && !this.hiddenPhoneNumber) {
                    this.currentScreen = SCREENS.ADD_PHONE
                  } else {
                    if (isPassenger) {
                      return this.getRides((ridesData) => {
                        this.rideList = ridesData.data
                        this.handleRedirect({ userData: data, currentRoute, isPassenger })
                      })
                    }

                    this.handleRedirect({ userData: data, currentRoute, isPassenger })
                  }
                }
              }
            })
          }, (loader) => { this.loginLoader = loader })
        }
      })
    },
    onCreateAccount () {
      this.$router.push('/session/sign-up')
    },
    async sendPhone (phone) {
      this.loginLoader = true

      try {
        await this.request('PATCH', '/me', {
          data: {
            phone: phone
          }
        })

        await this.request('POST', '/phone/verify', {
          data: {
            phone: phone
          }
        })

        this.currentScreen = SCREENS.VERIFY_PHONE
      } catch (error) {
        if (error.type === 'phone.not_unique') {
          await this.request('POST', '/phone/reassign', {
            data: {
              phone: phone
            }
          })

          this.currentScreen = SCREENS.VERIFY_PHONE
        }
      }

      this.loginLoader = false
    },
    verifyPhone (code) {
      this.request('POST', '/phone/verify/code', {
        data: {
          phone: this.phone,
          code: code
        }
      }, () => {
        this.$router.push('/rides').catch(() => {
          window.location.href = '/rides'
        })
      }, (loader) => {
        this.loginLoader = loader
      })
    },
    backToAddPhone () {
      this.currentScreen = SCREENS.ADD_PHONE
    }
  }
}
</script>

<style lang="scss" scoped>
.login-content {
      width: 90%;
      max-width: 350px;
}
.login-logo {
    margin-bottom: 5%;
    margin-top: 40px;
}
.background-cover {
  background-size: cover !important;
}
.forgot-password-link {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: -16px;
}

.forgot-password {
  margin-bottom: 25px !important;
}

.subtitle {
  color: #ffffff;
}

/deep/ .v-label--active.primary--text {
  color: white !important;
}
  .login-wrapper {
    height: 100%;
    background-color: #ffffff;

    .container {
      height: 100%;

      .custom-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .phone-field {
          margin: 20px 0;
        }

        .edit-phone {
          cursor: pointer;
        }

        .phone-visible {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin-left: 15px;
          }
        }
      }
    }
  }
</style>
