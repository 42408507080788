<template>
  <v-form
    ref="formEmail"
    lazy-validation
    class="pt-2">
    <v-text-field
      v-model="phone"
      :max-length="15"
      class="red-validation mt-3"
      type="tel"
      label="Telefoonnummer"
      placeholder="06-12345678"
      required
    />
    <div class="mt-3">
      <v-btn
        id="passenger-login-btn"
        :loading="loader"
        large
        block
        dark
        color="pink"
        class="login-button"
        @click="submitPhone">Volgende</v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'Phone',
  components: {},
  props: {
    loader: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      phone: ''
    }
  },
  methods: {
    submitPhone () {
      this.$emit('onSubmitPhone', this.phone)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
